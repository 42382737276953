import { Loading } from 'components/Loading';
import * as React from 'react';
import { lazyLoad } from '../utils/loadable';

export const JobsContainer = lazyLoad(
  () => import('./JobsContainer'),
  module => module.JobsContainer,
  {
    fallback: <Loading />,
  },
);

export const PreviewJobContainer = lazyLoad(
  () => import('./PreviewJobContainer'),
  module => module.PreviewJobContainer,
  {
    fallback: <Loading />,
  },
);

export const FAQContainer = lazyLoad(
  () => import('./FAQContainer'),
  module => module.FAQContainer,
  {
    fallback: <Loading />,
  },
);

export const RegisterContainer = lazyLoad(
  () => import('./RegisterContainer'),
  module => module.RegisterContainer,
  {
    fallback: <Loading />,
  },
);

export const LoginContainer = lazyLoad(
  () => import('./LoginContainer'),
  module => module.LoginContainer,
  {
    fallback: <Loading />,
  },
);

export const CandidateJobContainer = lazyLoad(
  () => import('./CandidateJobContainer'),
  module => module.CandidateJobContainer,
  {
    fallback: <Loading />,
  },
);

export const EventsContainer = lazyLoad(
  () => import('./EventsContainer'),
  module => module.EventsContainer,
  {
    fallback: <Loading />,
  },
);

export const PrivateRoutesContainer = lazyLoad(
  () => import('./PrivateRoutesContainer'),
  module => module.PrivateRoutesContainer,
  {
    fallback: <Loading />,
  },
);

export const SettingsContainer = lazyLoad(
  () => import('./SettingsContainer'),
  module => module.SettingsContainer,
  {
    fallback: <Loading />,
  },
);

export const MyCandidatesContainer = lazyLoad(
  () => import('./MyCandidatesContainer'),
  module => module.MyCandidatesContainer,
  {
    fallback: <Loading />,
  },
);

export const RecruitContainer = lazyLoad(
  () => import('./RecruitContainer'),
  module => module.RecruitContainer,
  {
    fallback: <Loading />,
  },
);

export const PrivacyPolicyContainer = lazyLoad(
  () => import('./PrivacyPolicyContainer'),
  module => module.PrivacyPolicyContainer,
  {
    fallback: <Loading />,
  },
);
export const CandidateUnsubscribeContainer = lazyLoad(
  () => import('./CandidateUnsubscribeContainer'),
  module => module.CandidateUnsubscribeContainer,
  {
    fallback: <Loading />,
  },
);
export const CandidateMarketJobContainer = lazyLoad(
  () => import('./CandidateMarketJobContainer'),
  module => module.CandidateMarketJobContainer,
  {
    fallback: <Loading />,
  },
);
