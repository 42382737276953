import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../types';
import { initialState } from './slice';

const selectDomain = (s: RootState) => s.register || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  register => register.loading,
);

export const selectError = createSelector(
  [selectDomain],
  register => register.error,
);

export const selectRegisteredAgent = createSelector(
  [selectDomain],
  ({ registeredAgent }) => registeredAgent,
);

export const selectPolicy = createSelector(
  [selectDomain],
  ({ policy }) => policy,
);

export const selectPolicySections = createSelector(
  [selectDomain],
  ({ policy }) => {
    let sections = policy.body
      .split('##')
      .filter(s => s)
      .map(str => {
        return {
          title: str.substring(str.indexOf('\n'), 0),
          contents: str
            .substring(str.indexOf('\n') + 1)
            .replace(/\s*\[.*?\]\s*/g, '')
            .split('\n')
            .filter(c => c),
        };
      });
    return sections;
  },
);

export const selectUserInvitation = createSelector(
  [selectDomain],
  ({ invitation }) => invitation,
);
