import { Routes } from '../global/Routes';

export interface MenuItem {
  index: number;
  translationKey: string;
  route: string;
  icon: string;
}

export const topMenuItemList: MenuItem[] = [
  {
    index: 0,
    translationKey: 'jobs',
    route: Routes.JOBS,
    icon: 'jobs',
  },
  {
    index: 1,
    translationKey: 'candidates',
    route: Routes.MY_CANDIDATES,
    icon: 'candidates',
  },
  {
    index: 2,
    translationKey: 'events',
    route: Routes.EVENTS,
    icon: 'events',
  },
  {
    index: 3,
    translationKey: 'recruit',
    route: Routes.RECRUIT,
    icon: 'leads',
  },
];

export const bottomMenuItemList: MenuItem[] = [
  {
    index: 4,
    translationKey: 'faq',
    route: Routes.FAQ,
    icon: 'faq',
  },
  {
    index: 5,
    translationKey: 'privacyPolicy',
    route: Routes.PRIVACY_POLICY,
    icon: 'privacy_policy',
  },
];
