import { call, put, takeLatest } from 'redux-saga/effects';
import { ApiClient } from 'utils/api';
import { actions } from './slice';
import { Action, IAgentSettings } from 'models';
import { actions as authActions } from '../auth/slice';
function* fetchAgentSettingsHandler({ payload }: Action) {
  const requestURL = `/Agent`;

  try {
    const data: IAgentSettings = yield call(
      ApiClient.get,
      `${requestURL}/${payload}/settings`,
    );
    yield put(actions.setAgentSettings(data));
  } catch (err) {
    yield put(actions.error({ message: 'There was an error', status: 404 }));
  }
}

function* updateSettingsHandler({ payload }: Action) {
  const requestURL = `/Agent`;

  try {
    if (payload.payload.document.fileContentBase64) {
      yield call(
        ApiClient.post,
        `${requestURL}/${payload.id}/document`,
        payload.payload.document,
      );
    }
    delete payload.payload.document;
    yield call(
      ApiClient.post,
      `${requestURL}/${payload.id}/settings`,
      payload.payload,
    );
    yield put(actions.fetchAgentSettings(payload.id));
  } catch (err) {
    yield put(actions.error({ message: 'There was an error', status: 404 }));
  }
}

function* deleteDocumentHandler({ payload }: Action) {
  const requestURL = `/Agent`;

  try {
    yield call(ApiClient.delete, `${requestURL}/${payload}/document`);
    yield put(actions.fetchAgentSettings(payload));
  } catch (err) {
    yield put(actions.error({ message: 'There was an error', status: 404 }));
  }
}

function* deleteAgentHandler({ payload }: Action) {
  const requestURL = '/Agent';
  try {
    yield call(ApiClient.delete, `${requestURL}/${payload}`);
    yield put(authActions.logoutUser(true));
  } catch (err) {
    yield put(actions.error({ message: 'There was an error', status: 404 }));
  }
}

export default function* agentsContainerSaga() {
  yield takeLatest(actions.fetchAgentSettings.type, fetchAgentSettingsHandler);
  yield takeLatest(actions.updateSettings.type, updateSettingsHandler);
  yield takeLatest(actions.deleteDocument.type, deleteDocumentHandler);
  yield takeLatest(actions.deleteAgent.type, deleteAgentHandler);
}
