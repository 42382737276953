// @ts-nocheck
import axios, { AxiosError } from 'axios';
import { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { Environment } from 'global/Environment';
import { getAuthCookie } from './helpers/authCookie';
import { GlobalNotifications } from './../global/GlobalNotifications';
import { actions } from '../store/appSlice';
import { store } from 'store/configureStore';
import { actions as authActions } from 'store/auth/slice';

export class ResponseError extends Error {
  public error: AxiosError;

  constructor(response: AxiosError) {
    super(response.message);
    this.error = response;
  }
}

export class ApiClient {
  private static readonly baseUrl: string = Environment.API_URL;
  private static readonly apiKey: string = Environment.API_KEY;
  private static readonly instance: AxiosInstance = axios.create();

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to fetch
   * @param  {object} body The query parameters as an object
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async get(
    path: string,
    queryParams?: Object,
    options?: AxiosRequestConfig,
  ) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'GET',
      params: { ...queryParams },
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to fetch
   * @param  {BodyInit} body The payload body to post
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async post(
    path: string,
    payload?: BodyInit,
    options?: AxiosRequestConfig,
  ) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'POST',
      data: payload,
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to fetch
   * @param  {BodyInit} body The payload body to update
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async put(
    path: string,
    payload?: BodyInit,
    options?: AxiosRequestConfig,
  ) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'PUT',
      data: payload,
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {string} path The url path to delete
   * @param  {AxiosRequestConfig} response Extra axios config params
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */
  static async delete(path: string, options?: AxiosRequestConfig) {
    return ApiClient.callApi(path, {
      ...options,
      method: 'DELETE',
    });
  }

  /**
   * Checks if a network request came back fine, and throws an error if not
   *
   * @param  {AxiosResponse} response A response from a network request
   *
   * @return {object|undefined} Returns the data from Axios response, or throws an error
   */

  static checkStatus({ status, data, config }: AxiosResponse) {
    if (status === 401) {
      localStorage.removeItem('TOKEN_REGISTER');
      localStorage.removeItem('AUTH_AGENT');
      localStorage.removeItem('AUTH_AGENT_TOKEN');
      localStorage.removeItem('AUTH_SOFT_AGENT');
      localStorage.removeItem('AUTH_SOFT_AGENT_TOKEN');
      store.dispatch(authActions.resetAuthState());
    }
    //removes id from url
    let url = config.url.replace(/[/][0-9]+[*/]?/g, '');
    url = url.split('?')[0];

    const notificationPath =
      status === 500
        ? 'InternalServerError'
        : data?.succeeded
        ? `${url}:${config.method}`
        : `${url}:${config.method}:${data.MessageCode}`;

    const notification = GlobalNotifications.get(notificationPath);

    if (notification) {
      store.dispatch(actions.setNotification(notification));
    }

    if (status >= 200 && status < 300) {
      return data.data;
    }
  }

  /**
   * Requests a URL, returning a promise
   *
   * @param  {string} path       The URL we want to request
   * @param  {AxiosRequestConfig} [options] The options we want to pass to "fetch"
   *
   * @return {object}           The response data
   */
  private static async callApi(path: string, options?: AxiosRequestConfig) {
    const requestConfig: AxiosRequestConfig = {
      method: options?.method,
      baseURL: ApiClient.baseUrl,
      url: path,
      params: options && options.params,
      data: options && options.data,
      headers: {
        ApiKey: ApiClient.apiKey,
        'Content-Type': 'application/json',
        ...(options && options.headers),
      },
      responseType:
        options && options.responseType ? options.responseType : 'json',
    };

    try {
      this.authInterceptor();
      const fetchResponse: AxiosResponse = await this.instance(requestConfig);

      return ApiClient.checkStatus(fetchResponse);
    } catch (err) {
      ApiClient.checkStatus(err.response);
      throw Error;
    }
  }

  private static authInterceptor(): void {
    this.instance.interceptors.request.use(
      config => {
        const registerToken = localStorage.getItem('TOKEN_REGISTER');
        const agentToken = localStorage.getItem('AUTH_AGENT_TOKEN');
        const candidateToken = localStorage.getItem('AUTH_CANDIDATE_TOKEN');
        const softAgentToken = localStorage.getItem('AUTH_SOFT_AGENT_TOKEN');
        const softCandidateToken = localStorage.getItem(
          'AUTH_SOFT_CANDIDATE_TOKEN',
        );

        let token = agentToken
          ? agentToken
          : registerToken
          ? registerToken
          : softAgentToken
          ? softAgentToken
          : undefined;

        const recommendationRegex = new RegExp(/^\/re\/[\w./]+$/);
        const applyRegex = new RegExp(/^\/apply\/[0-9]+.*$/);
        const unsubscribeRegex = new RegExp(/^\/unsubscribe$/);
        if (
          candidateToken &&
          recommendationRegex.test(window.location.pathname)
        ) {
          token = candidateToken;
        }
        if (
          softCandidateToken &&
          (applyRegex.test(window.location.pathname) ||
            unsubscribeRegex.test(window.location.pathname))
        ) {
          token = softCandidateToken;
        }
        if (
          !agentToken &&
          !softAgentToken &&
          candidateToken &&
          !recommendationRegex.test(window.location.pathname)
        ) {
          token = undefined;
        }

        if (token) {
          config.headers = {
            ...config.headers,
            ...{
              Authorization: `Bearer ${token}`,
            },
          };
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }
}
