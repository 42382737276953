export interface IUserLogin {
  email?: string;
  password?: string;
  role?: string;
  urlKey?: string;
}
export type UserLoginPayload = Pick<
  IUserLogin,
  'email' | 'password' | 'role' | 'urlKey'
>;

export interface IAgentSettings {
  documentUrl: string;
  documentUploadedAt: string;
  externalUrl: string;
  emailNewJobs: boolean;
  emailCandidateUpdates: boolean;
  emailNewsletter: boolean;
  emailEvents: boolean;
  name: string;
  email: string;
  phoneNumber: string;
  id: number;
  insertedAt: string;
  updatedAt: string;
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  document?: string;
  jobTitle?: string;
  function?: string;
  industry?: string;
  company?: string;
  location?: string;
}

export type AgentsState = {
  agentSettings?: IAgentSettings;
  modalStatus?: boolean;
};

export interface ISettingsUpdate {
  agentPatchDTO?: Array<IUpdate>;
  currentPassword?: string;
  newPassword?: string;
  document?: IDocumentUpload;
}

export interface IUpdate {
  path?: string;
  op?: string;
  value?: string | number | boolean;
}

export interface ISettingsRedux {
  payload: ISettingsUpdate;
  id: number;
}

export interface IDocumentUpload {
  fileName?: string;
  fileContentBase64?: string;
  currentPassword?: string;
}

export enum FunctionEnum {
  ADMINISTRATION = 'Administration',
  B2B_SALES = 'B2B Sales',
  B2C_SALES = 'B2C Sales',
  COMMUNICATION_PR = 'Communication / PR',
  CONSULTING = 'Consulting',
  CUSTOMER_SERVICE_SUPPORT = 'Customer service / support',
  DESIGN = 'Design',
  ENGINEERING = 'Engineering',
  EVENT_MANAGEMENT = 'Event management',
  FINANCE_ACCOUNTING = 'Finance / Accounting',
  GENERAL_MANAGEMENT = 'General Management',
  HUMAN_RESOURCES = 'Human Resources',
  IT_PROGRAMMING = 'IT / Programming',
  LAW = 'Law',
  LOGISTICS = 'Logistics',
  MANAGEMENT_CONSULTING = 'Management consulting',
  MANUFACTURING = 'Manufacturing',
  MARKETING = 'Marketing',
  MEDIA = 'Media',
  OPERATIONS_MANAGEMENT = 'Operations management',
  OTHER = 'Other',
  PURCHASING = 'Purchasing',
  RESEARCH_DEVELOPMENT = 'Research & Development',
}

export enum Industry {
  ADVERTISING_PR_MARKET_RESEARCH = 'Advertising / PR / Market Research',
  AGRICULTURE_FORESTRY_HUNTING_FISHING = 'Agriculture, Forestry, Hunting & Fishing',
  AIRLINE_TRAVEL_TOURISM = 'Airline, Travel & Tourism',
  AUDITING_ACCOUNTING_TAXATION = 'Auditing, Accounting, Taxation',
  AUTOMOTIVE = 'Automotive',
  BANK_FINANCE_INSURANCE = 'Bank, Finance & Insurance',
  BIOTECHNOLOGY = 'Biotechnology',
  CHEMICAL_PETROLUEM = 'Chemical / Petroluem',
  CONSTRUCTION_DESIGN = 'Construction / Design',
  CULTURE_ENTERTAINMENT_LEISURE = 'Culture, Entertainment & Leisure',
  DEFENCE = 'Defence',
  E_COMMERCE = 'e-Commerce',
  EDUCATION = 'Education',
  ELECTRONICS = 'Electronics',
  EMBASSIES_INTERNATIONAL_ORGANIZATIONS = 'Embassies & International Organizations',
  EMPLOYERS_ORGANIZATIONS_INDUSTRY_TRADE_GROUPS = 'Employers Organizations / Industry Trade Groups',
  ENERGY_POWER_UTILITIES = 'Energy / Power / Utilities',
  FAST_MOVING_CONSUMER_GOODS = 'Fast Moving Consumer Goods',
  FOOD_PROCESSING_SALES = 'Food Processing & Sales',
  HAIR_BEAUTY_CARE = 'Hair & Beauty Care',
  HEALTHCARE_WELLBEING = 'Healthcare & wellbeing',
  HOSPITALITY = 'Hospitality',
  HUMAN_RESOURCES_RECRUITMENT = 'Human Resources & Recruitment',
  IT_SERVICES = 'IT Services',
  LEGAL_SERVICES = 'Legal Services',
  LOGISTICS = 'Logistics',
  MANAGEMENT_CONSULTING = 'Management Consulting',
  MANUFACTURING = 'Manufacturing',
  MEDIA_ENTERTAINEMENT = 'Media / Entertainement',
  METALS_MINING = 'Metals / Mining',
  OTHER = 'Other',
  PHARMACEUTICALS = 'Pharmaceuticals',
  PUBLIC_ADMINISTRATION = 'Public Administration',
  REAL_ESTATE = 'Real Estate',
  REPAIR_INSTALLATION = 'Repair / Installation',
  RETAIL = 'Retail',
  STAFFING_EMPLOYMENT_AGENCIES = 'Staffing & Employment Agencies',
  TELECOMMUNICATIONS = 'Telecommunications',
}

export enum ExperienceLevel {
  YOUNG_PROFESSIONAL = 'Young Professional',
  PROFESSIONAL = 'Professional',
  SENIOR_PROFESSIONAL = 'Senior Professional',
}

export const experienceList = [
  { label: '', option: '' },
  {
    label: 'Young Professional',
    option: ExperienceLevel.YOUNG_PROFESSIONAL,
  },
  {
    label: 'Professional',
    option: ExperienceLevel.PROFESSIONAL,
  },
  {
    label: 'Senior Professional',
    option: ExperienceLevel.SENIOR_PROFESSIONAL,
  },
];
export const functionList = [
  { label: '', option: '' },
  {
    label: 'Administration',
    option: FunctionEnum.ADMINISTRATION,
  },
  { label: 'B2B Sales', option: FunctionEnum.B2B_SALES },
  { label: 'B2C Sales', option: FunctionEnum.B2C_SALES },
  {
    label: 'Communication / PR',
    option: FunctionEnum.COMMUNICATION_PR,
  },
  { label: 'Consulting', option: FunctionEnum.CONSULTING },
  {
    label: 'Customer service / support',
    option: FunctionEnum.CUSTOMER_SERVICE_SUPPORT,
  },
  { label: 'Design', option: FunctionEnum.DESIGN },
  { label: 'Engineering', option: FunctionEnum.ENGINEERING },
  {
    label: 'Event management',
    option: FunctionEnum.EVENT_MANAGEMENT,
  },
  {
    label: 'Finance / Accounting',
    option: FunctionEnum.FINANCE_ACCOUNTING,
  },
  {
    label: 'General Management',
    option: FunctionEnum.GENERAL_MANAGEMENT,
  },
  {
    label: 'Human Resources',
    option: FunctionEnum.HUMAN_RESOURCES,
  },
  {
    label: 'IT / Programming',
    option: FunctionEnum.IT_PROGRAMMING,
  },
  { label: 'Law', option: FunctionEnum.LAW },
  { label: 'Logistics', option: FunctionEnum.LOGISTICS },
  {
    label: 'Management consulting',
    option: FunctionEnum.MANAGEMENT_CONSULTING,
  },
  {
    label: 'Manufacturing',
    option: FunctionEnum.MANUFACTURING,
  },
  { label: 'Marketing', option: FunctionEnum.MARKETING },
  { label: 'Media', option: FunctionEnum.MEDIA },
  {
    label: 'Operations management',
    option: FunctionEnum.OPERATIONS_MANAGEMENT,
  },
  { label: 'Other', option: FunctionEnum.OTHER },
  { label: 'Purchasing', option: FunctionEnum.PURCHASING },
  {
    label: 'Research & Development',
    option: FunctionEnum.RESEARCH_DEVELOPMENT,
  },
];

export const industryList = [
  { label: '', option: '' },
  {
    label: 'Advertising / PR / Market Research',
    option: Industry.ADVERTISING_PR_MARKET_RESEARCH,
  },
  {
    label: 'Agriculture, Forestry, Hunting & Fishing',
    option: Industry.AGRICULTURE_FORESTRY_HUNTING_FISHING,
  },
  {
    label: 'Airline, Travel & Tourism',
    option: Industry.AIRLINE_TRAVEL_TOURISM,
  },
  {
    label: 'Auditing, Accounting, Taxation',
    option: Industry.AUDITING_ACCOUNTING_TAXATION,
  },
  { label: 'Automotive', option: Industry.AUTOMOTIVE },
  {
    label: 'Bank, Finance & Insurance',
    option: Industry.BANK_FINANCE_INSURANCE,
  },
  { label: 'Biotechnology', option: Industry.BIOTECHNOLOGY },
  {
    label: 'Chemical / Petroluem',
    option: Industry.CHEMICAL_PETROLUEM,
  },
  {
    label: 'Construction / Design',
    option: Industry.CONSTRUCTION_DESIGN,
  },
  {
    label: 'Culture, Entertainment & Leisure',
    option: Industry.CULTURE_ENTERTAINMENT_LEISURE,
  },
  { label: 'Defence', option: Industry.DEFENCE },
  {
    label: 'E-Commerce',
    option: Industry.E_COMMERCE,
  },
  { label: 'Education', option: Industry.EDUCATION },
  {
    label: 'Electronics',
    option: Industry.ELECTRONICS,
  },
  {
    label: 'Embassies & International Organizations',
    option: Industry.EMBASSIES_INTERNATIONAL_ORGANIZATIONS,
  },
  {
    label: 'Employers Organizations / Industry Trade Groups',
    option: Industry.EMPLOYERS_ORGANIZATIONS_INDUSTRY_TRADE_GROUPS,
  },
  {
    label: 'Energy / Power / Utilities',
    option: Industry.ENERGY_POWER_UTILITIES,
  },
  {
    label: 'Fast Moving Consumer Goods',
    option: Industry.FAST_MOVING_CONSUMER_GOODS,
  },
  {
    label: 'Food Processing & Sales',
    option: Industry.FOOD_PROCESSING_SALES,
  },
  {
    label: 'Hair & Beauty Care',
    option: Industry.HAIR_BEAUTY_CARE,
  },
  {
    label: 'Healthcare & wellbeing',
    option: Industry.HEALTHCARE_WELLBEING,
  },
  {
    label: 'Hospitality',
    option: Industry.HOSPITALITY,
  },
  {
    label: 'Human Resources & Recruitment',
    option: Industry.HUMAN_RESOURCES_RECRUITMENT,
  },
  {
    label: 'IT Services',
    option: Industry.IT_SERVICES,
  },
  { label: 'Legal Services', option: Industry.LEGAL_SERVICES },
  {
    label: 'Logistics',
    option: Industry.LOGISTICS,
  },
  {
    label: 'Management Consulting',
    option: Industry.MANAGEMENT_CONSULTING,
  },
  {
    label: 'Manufacturing',
    option: Industry.MANUFACTURING,
  },
  {
    label: 'Media / Entertainement',
    option: Industry.MEDIA_ENTERTAINEMENT,
  },
  {
    label: 'Metals / Mining',
    option: Industry.METALS_MINING,
  },
  {
    label: 'Other',
    option: Industry.OTHER,
  },
  {
    label: 'Pharmaceuticals',
    option: Industry.PHARMACEUTICALS,
  },
  {
    label: 'Public Administration',
    option: Industry.PUBLIC_ADMINISTRATION,
  },
  {
    label: 'Real Estate',
    option: Industry.REAL_ESTATE,
  },
  {
    label: 'Repair / Installation',
    option: Industry.REPAIR_INSTALLATION,
  },
  {
    label: 'Retail',
    option: Industry.RETAIL,
  },
  {
    label: 'Staffing & Employment Agencies',
    option: Industry.STAFFING_EMPLOYMENT_AGENCIES,
  },
  {
    label: 'Telecommunications',
    option: Industry.TELECOMMUNICATIONS,
  },
];
