export const GlobalNotifications = new Map();

GlobalNotifications.set('InternalServerError', {
  severityType: 'error',
  message: 'Internal server error',
});
GlobalNotifications.set('/Job/selfrecommend/agentjob:post:ITEM_ALREADY_EXIST', {
  severityType: 'error',
  message: 'You are already in the process',
});
GlobalNotifications.set('/Job/recommend:post:ITEM_ALREADY_EXIST', {
  severityType: 'error',
  message: 'Another agent has already recommended this person',
});
GlobalNotifications.set('/Account/resetPassword:post', {
  severityType: 'success',
  message: 'Password changed',
});
GlobalNotifications.set('/Account/requestResetPassword:post', {
  severityType: 'info',
  message: 'Password reset instructions sent to:',
});
GlobalNotifications.set(
  '/Account/authenticate:post:ONE_OR_MORE_ITEMS_NOT_VALID',
  {
    severityType: 'error',
    message: 'Invalid email or password',
  },
);
GlobalNotifications.set('/Agentdocument:delete', {
  severityType: 'success',
  message: 'Document deleted',
});
GlobalNotifications.set('/Agentdocument:post', {
  severityType: 'success',
  message: 'Settings updated',
});
GlobalNotifications.set('/Agentsettings:post', {
  severityType: 'success',
  message: 'Settings updated',
});
GlobalNotifications.set('/Agentsettings:post:ONE_OR_MORE_ITEMS_NOT_VALID', {
  severityType: 'error',
  message: 'Wrong password',
});
GlobalNotifications.set('/Lead/agent:post', {
  severityType: 'success',
  message: 'Thank you! We will be in touch as soon as possible!',
});
GlobalNotifications.set('/Lead/agent/contact:post', {
  severityType: 'success',
  message: 'Thank you! We will reach out to them as soon as possible!',
});
GlobalNotifications.set('/Eventregister:put:ITEM_ALREADY_EXIST', {
  severityType: 'info',
  message: "You're already signed up",
});
GlobalNotifications.set('/Candidate/apply:post:ITEM_ALREADY_EXIST', {
  severityType: 'error',
  message: 'Email already in use',
});
GlobalNotifications.set('/Agent:delete', {
  severityType: 'success',
  message: 'Your account has been successfully deleted',
});
GlobalNotifications.set('/TalentPools:delete:ITEM_NOT_FOUND', {
  severityType: 'error',
  message: "You're already removed from the Talent Pool",
});
