export interface IEvent {
  id: number;
  city: string;
  closedAt?: string;
  description?: string;
  emailImageBase64?: string;
  emailImageUrl?: string;
  guestLimit?: number;
  imageBase64?: string;
  imageUrl?: string;
  insertedAt?: string;
  locale: string;
  openedAt?: string;
  speaker: string;
  speakerDescription: string;
  startDate: string;
  startTime: string;
  status?: EventStatus;
  title: string;
  updatedAt?: string;
  agentTagsIncluded?: string[];
  agentTagsExcluded?: string[];
  agentEventStatus?: string;
}

export enum EventStatus {
  DRAFT = 'draft',
  DISPLAYED = 'visible',
  OPEN = 'open',
  CLOSE = 'closed',
  ARCHIVED = 'archived',
}

export enum EventAttendeesStatus {
  INTERESTED = 'interested',
  ATTENDING = 'attending',
  ATTENDED = 'attended',
  CANCELED = 'canceled',
}

export type EventsState = {
  events: IEvent[];
  registeredForEventId?: number;
};

export interface EventsContainerParams {
  eventId: string;
}
