import React from 'react';

import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { actions } from '../store/appSlice';

type ToastProps = {
  severityType: SeverityType;
  message: string;
  shown: boolean;
};

type SeverityType = 'error' | 'info' | 'success' | 'warning';

export const Toast: React.FC<ToastProps> = ({
  severityType,
  message,
  shown,
}) => {
  const [openToast, setOpenToast] = React.useState(shown);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenToast(false);
    dispatch(actions.resetNotification());
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
        open={openToast}
        autoHideDuration={severityType !== 'error' ? 5000 : null}
      >
        <Alert onClose={handleClose} variant="filled" severity={severityType}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
