import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../types/ErrorType';
import { createSlice } from '../../utils/@reduxjs/toolkit';
import { AuthUser, IUserLogin, NewPasswordPayload } from '../../models';

export interface AuthState {
  isAuthenticated: boolean;
  authUser?: AuthUser;
  passwordResetRequested?: boolean;
  newPasswordConfirm?: boolean;
  resetEmail?: string;
  authCandidate?: AuthUser;
  authSoftCandidate?: AuthUser;
  isCandidateAuthenticated?: boolean;
  isSoftCandidateAuthenticated?: boolean;
  authSoftAgent?: AuthUser;
  isSoftAgentAuthenticated?: boolean;
  redirectTo?: string;
  isLoggingOut: boolean;
  loading: boolean;
  error: ErrorType | null;
  isValidToken?: boolean;
  validatingToken?: boolean;
}

export const initialState: AuthState = {
  isAuthenticated: localStorage.getItem('AUTH_AGENT') ? true : false,
  isCandidateAuthenticated: false,
  isSoftAgentAuthenticated: localStorage.getItem('AUTH_SOFT_AGENT')
    ? true
    : false,
  isLoggingOut: false,
  loading: false,
  error: null,
  isValidToken: undefined,
  validatingToken: true,
};

const AuthenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticateUser(state, action: PayloadAction<IUserLogin>) {
      state.loading = true;
    },
    fetchAuthenticatedUser(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    setAuthenticatedUser(state, { payload }: PayloadAction<AuthUser>) {
      state.loading = false;
      state.isAuthenticated = true;
      state.authUser = payload;
      state.error = null;
    },
    setAuthenticatedCandidate(state, { payload }: PayloadAction<AuthUser>) {
      state.loading = false;
      state.isCandidateAuthenticated = true;
      state.authCandidate = payload;
      state.error = null;
    },
    setSoftAuthenticatedCandidate(state, { payload }: PayloadAction<AuthUser>) {
      state.loading = false;
      state.isSoftCandidateAuthenticated = true;
      state.authSoftCandidate = payload;
      state.error = null;
    },
    setSoftAuthenticatedAgent(state, { payload }: PayloadAction<AuthUser>) {
      state.loading = false;
      state.isSoftAgentAuthenticated = true;
      state.authSoftAgent = payload;
      state.error = null;
    },
    resetPassword(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    setResetPassword(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.passwordResetRequested = true;
      state.resetEmail = payload;
    },
    createNewPassword(state, action: PayloadAction<NewPasswordPayload>) {
      state.loading = true;
      state.resetEmail = undefined;
      state.passwordResetRequested = false;
    },
    createNewPasswordSuccess(state) {
      state.loading = false;
      state.newPasswordConfirm = true;
      state.isValidToken = undefined;
    },
    logoutUser(state, action: PayloadAction<boolean>) {
      state.loading = true;
      state.isLoggingOut = true;
    },
    resetAuthState(state) {
      state.loading = false;
      state.isAuthenticated = false;
      state.authUser = undefined;
      state.passwordResetRequested = false;
      state.resetEmail = undefined;
      state.authCandidate = undefined;
      state.isCandidateAuthenticated = false;
      state.isSoftAgentAuthenticated = false;
    },
    resetCandidateAuthState(state) {
      state.authCandidate = undefined;
      state.isCandidateAuthenticated = false;
    },
    setRedirectToAfterLogin(state, action: PayloadAction<string>) {
      state.redirectTo = action.payload;
    },
    validateToken(state, action: PayloadAction<string>) {
      state.validatingToken = true;
    },
    setValidatedToken(state, { payload }: PayloadAction<boolean>) {
      state.isValidToken = payload;
      state.validatingToken = false;
    },
    error(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = AuthenticationSlice;
