import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../utils/@reduxjs/toolkit';
import { ContainerState } from '../../types/ContainerState';
import { ErrorType } from '../../types/ErrorType';
import { AgentsState, IAgentSettings, ISettingsRedux } from '../../models';

export const initialState: AgentsState & ContainerState = {
  loading: false,
  error: null,
  agentSettings: undefined,
  modalStatus: false,
};

const agentsContainerSlice = createSlice({
  name: 'agentsContainer',
  initialState,
  reducers: {
    setModalStatus(state, { payload }: PayloadAction<boolean>) {
      state.modalStatus = payload;
    },
    fetchAgentSettings(state, { payload }: PayloadAction<number>) {
      state.loading = true;
      state.error = null;
    },
    setAgentSettings(state, { payload }: PayloadAction<IAgentSettings>) {
      state.loading = false;
      state.agentSettings = payload;
    },
    deleteDocument(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = null;
    },
    updateSettings(state, { payload }: PayloadAction<ISettingsRedux>) {
      state.loading = true;
      state.error = null;
    },
    resetAgentSettings(state) {
      state.agentSettings = undefined;
    },
    deleteAgent(state, { payload }: PayloadAction<number>) {
      state.modalStatus = false;
    },
    error(state, { payload }: PayloadAction<ErrorType>) {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = agentsContainerSlice;
