export class Routes {
  static JOBS = '/jobs';
  static JOB_DETAILS = '/jobs/:jobId';
  static JOB_PREVIEW = '/jobs/preview/:previewKey';
  static MY_CANDIDATES = '/candidates';
  static EVENTS = '/events';
  static EVENTS_REGISTER = '/events/:eventId';
  static RECRUIT = '/recruit';
  static SETTINGS = '/settings';
  static FAQ = '/faq';
  static PRIVACY_POLICY = '/privacy-policy';

  static LOGIN = '/login';
  static RESET_PASSWORD = '/resetpassword';
  static NEW_PASSWORD = '/resetpassword/:key';
  static REGISTER = '/register/:key';

  static CANDIDATE_JOB_DETAILS = '/re/:key';
  static CANDIDATE_JOB_DETAILS_DECLINE = '/re/:key/decline';
  static CANDIDATE_UNSUBSCRIBE = '/unsubscribe';
  static CANDIDATE_MARKET_JOB = '/apply/:jobId';
}
