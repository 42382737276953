export const isFullPageRoute = () => {
  const recommendationRegex = new RegExp(/^\/re\/[\w./]+$/);
  const jobPreviewrRegex = new RegExp(/^\/jobs\/preview\/[\w.-]+$/);
  const unsubscribeRegex = new RegExp(/^\/unsubscribe$/);
  const applyRegex = new RegExp(/^\/apply\/[0-9]+.*$/);
  return (
    recommendationRegex.test(window.location.pathname) ||
    jobPreviewrRegex.test(window.location.pathname) ||
    applyRegex.test(window.location.pathname) ||
    unsubscribeRegex.test(window.location.pathname)
  );
};
