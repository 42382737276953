import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    // Standard language used
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    //Detects and caches a cookie from the language provided
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
