import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { AppState, Notification } from 'models';

export const initialState: AppState = {
  notification: null,
};

const appContainerSlice = createSlice({
  name: 'appContainer',
  initialState,
  reducers: {
    setNotification(state, { payload }: PayloadAction<Notification>) {
      state.notification = payload;
    },
    resetNotification(state) {
      state.notification = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = appContainerSlice;
