import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import 'styles/main.scss';
import { StylesProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import {
  PrivateRoutesContainer,
  RegisterContainer,
  CandidateJobContainer,
  LoginContainer,
  PreviewJobContainer,
  CandidateMarketJobContainer,
  CandidateUnsubscribeContainer,
} from 'containers';
import { Routes } from 'global/Routes';
import { useInjectReducer, useInjectSaga } from 'utils/reduxInjectors';
import { sliceKey, reducer } from '../store/appSlice';
import { selectNotification } from '../store/appSelectors';
import { Toast } from 'components/Toast';
import { IUserLogin, NewPasswordPayload, ROLES } from 'models';
import {
  sliceKey as authSliceKey,
  reducer as authReducer,
  actions as authActions,
} from '../store/auth/slice';
import { authenticationSaga } from '../store/auth/saga';
import {
  selectIsAuthenticated,
  selectResetEmail,
  selectIsSoftAgentAuthenticated,
  selectRedirectTo,
  selectLoading,
  selectIsLoggingOut,
} from 'store/auth/selectors';
import { selectRegisteredAgent } from 'store/register/selectors';

import './App.module.scss';
import { Loading } from 'components/Loading';

import { selectAgentSettings } from 'store/agent/selectors';
import agentsContainerSaga from '../store/agent/saga';

import {
  sliceKey as agentSliceKey,
  reducer as agentReducer,
  actions as agentActions,
} from '../store/agent/slice';
import { isFullPageRoute } from 'utils/helpers/routeUtil';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Lato',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectReducer({ key: authSliceKey, reducer: authReducer });
  useInjectSaga({ key: sliceKey, saga: authenticationSaga });

  useInjectReducer({ key: agentSliceKey, reducer: agentReducer });
  useInjectSaga({ key: agentSliceKey, saga: agentsContainerSaga });

  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenParam = params.get('token');
  let notification = useSelector(selectNotification);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isSoftAgentAuthenticated = useSelector(selectIsSoftAgentAuthenticated);
  const resetEmail = useSelector(selectResetEmail);
  const redirectTo = useSelector(selectRedirectTo);
  const registeredAgent = useSelector(selectRegisteredAgent);
  const isAuthLoading = useSelector(selectLoading);
  const isLoggingOut = useSelector(selectIsLoggingOut);
  const agentSettings = useSelector(selectAgentSettings);

  const authAgentId = localStorage.getItem('AUTH_AGENT')
    ? parseInt(JSON.parse(localStorage.getItem('AUTH_AGENT')!).id, 10)
    : localStorage.getItem('AUTH_SOFT_AGENT')
    ? parseInt(JSON.parse(localStorage.getItem('AUTH_SOFT_AGENT')!).id, 10)
    : null;
  if (
    (!agentSettings ||
      !agentSettings.id ||
      (agentSettings && agentSettings.id !== authAgentId)) &&
    (isAuthenticated || isSoftAgentAuthenticated) &&
    !isFullPageRoute()
  ) {
    authAgentId && dispatch(agentActions.fetchAgentSettings(authAgentId));
  }

  if (
    (window.location.pathname.includes(Routes.JOBS) ||
      window.location.pathname.includes(Routes.EVENTS)) &&
    tokenParam &&
    !isSoftAgentAuthenticated &&
    !isAuthenticated &&
    !isLoggingOut
  ) {
    localStorage.setItem('AUTH_SOFT_AGENT_TOKEN', tokenParam);
    dispatch(authActions.fetchAuthenticatedUser(tokenParam));
    dispatch(authActions.setRedirectToAfterLogin(window.location.href));
  }

  if (redirectTo && isSoftAgentAuthenticated) {
    window.location.href = redirectTo;
    if (
      window.location.pathname.includes(Routes.EVENTS) ||
      window.location.pathname.includes(Routes.JOBS)
    ) {
      dispatch(authActions.setRedirectToAfterLogin(''));
    }
  }

  useEffect(() => {
    if (
      (window.location.pathname.includes('/apply') ||
        window.location.pathname.includes(Routes.CANDIDATE_UNSUBSCRIBE)) &&
      tokenParam &&
      !isAuthenticated &&
      !isLoggingOut
    ) {
      localStorage.removeItem('AUTH_CANDIDATE_TOKEN');
      localStorage.removeItem('AUTH_CANDIDATE');
      localStorage.setItem('AUTH_SOFT_CANDIDATE_TOKEN', tokenParam);
      dispatch(authActions.fetchAuthenticatedUser(tokenParam));
      dispatch(authActions.setRedirectToAfterLogin(window.location.href));
    }
  }, []);

  const handleLogin = (payload: IUserLogin) => {
    dispatch(authActions.authenticateUser(payload));
  };

  if (registeredAgent && registeredAgent.email && !isAuthenticated) {
    const loginPayload: IUserLogin = {
      email: registeredAgent.email,
      password: registeredAgent.password,
      role: ROLES.AGENT,
    };
    handleLogin(loginPayload);
  }

  const handleResetPassword = (email: string) => {
    dispatch(authActions.resetPassword(email));
  };

  const handleNewPassword = (payload: NewPasswordPayload) => {
    dispatch(authActions.createNewPassword(payload));
  };

  const handleLogout = () => {
    dispatch(authActions.logoutUser(isSoftAgentAuthenticated ?? false));
    dispatch(agentActions.resetAgentSettings());
  };

  const handleCandidateAutorization = (payload: IUserLogin) => {
    dispatch(authActions.authenticateUser(payload));
  };

  const resetCandidateAuthState = () => {
    dispatch(authActions.resetCandidateAuthState());
  };

  const validateToken = (key: string) => {
    dispatch(authActions.validateToken(key));
  };

  return (
    <>
      {isAuthLoading ? (
        <Loading />
      ) : (
        <BrowserRouter>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Helmet titleTemplate="%s" defaultTitle="AgentumHQ agent">
                <meta name="description" content="Agentum" />
              </Helmet>
              {isAuthenticated || isSoftAgentAuthenticated ? (
                <PrivateRoutesContainer
                  handleLogout={handleLogout}
                  handleCandidateAutorization={handleCandidateAutorization}
                  resetCandidateAuthState={resetCandidateAuthState}
                />
              ) : (
                <Switch>
                  <Route path={'/(login|resetpassword)/(:key|)?'}>
                    <LoginContainer
                      handleLogin={handleLogin}
                      handleResetPassword={handleResetPassword}
                      handleNewPassword={handleNewPassword}
                      validateToken={validateToken}
                    />
                  </Route>
                  <Route path={Routes.REGISTER}>
                    <RegisterContainer />
                  </Route>
                  <Route path={'/re/:key/(decline|)?'}>
                    <CandidateJobContainer
                      handleCandidateAutorization={handleCandidateAutorization}
                      resetCandidateAuthState={resetCandidateAuthState}
                    />
                  </Route>
                  <Route path={Routes.JOB_PREVIEW}>
                    <PreviewJobContainer />
                  </Route>
                  <Route path={Routes.CANDIDATE_MARKET_JOB}>
                    <CandidateMarketJobContainer />
                  </Route>
                  <Route path={Routes.CANDIDATE_UNSUBSCRIBE}>
                    <CandidateUnsubscribeContainer />
                  </Route>
                  <Route path="/*">
                    <Redirect to={Routes.LOGIN} />
                  </Route>
                </Switch>
              )}
              {notification && (
                <Toast
                  shown={true}
                  message={
                    resetEmail
                      ? `${notification.message} ${resetEmail}`
                      : notification.message
                  }
                  severityType={notification.severityType}
                />
              )}
            </ThemeProvider>
          </StylesProvider>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
