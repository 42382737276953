import { CircularProgress } from '@material-ui/core';
import React from 'react';

export const Loading = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
        <span
          style={{
            marginTop: '.5rem',
          }}
        >
          Loading
        </span>
      </div>
    </div>
  );
};
