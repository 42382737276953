import { createSelector } from 'reselect';

import { RootState } from '../../types';
import { initialState } from './slice';

const selectDomain = (s: RootState) => s.agentsContainer || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  ({ loading }) => loading,
);
export const selectError = createSelector([selectDomain], ({ error }) => error);

export const selectAgentSettings = createSelector(
  [selectDomain],
  ({ agentSettings }) => agentSettings,
);

export const selectModalStatus = createSelector(
  [selectDomain],
  ({ modalStatus }) => modalStatus,
);
