import { createSelector } from 'reselect';
import { RootState } from '../../types';
import { initialState } from './slice';

const selectDomain = (s: RootState) => s.auth || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  auth => auth.loading,
);

export const selectError = createSelector([selectDomain], auth => auth.error);

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  auth => auth.isAuthenticated,
);

export const selectAuthUser = createSelector(
  [selectDomain],
  auth => auth.authUser,
);

export const selectIsCandidateAuthenticated = createSelector(
  [selectDomain],
  auth => auth.isCandidateAuthenticated,
);

export const selectAuthCandidate = createSelector(
  [selectDomain],
  auth => auth.authCandidate,
);
export const selectAuthSoftCandidate = createSelector(
  [selectDomain],
  auth => auth.authSoftCandidate,
);

export const selectPasswordResetRequested = createSelector(
  [selectDomain],
  auth => auth.passwordResetRequested,
);

export const selectNewPasswordConfirm = createSelector(
  [selectDomain],
  auth => auth.newPasswordConfirm,
);

export const selectResetEmail = createSelector(
  [selectDomain],
  auth => auth.resetEmail,
);

export const selectIsSoftAgentAuthenticated = createSelector(
  [selectDomain],
  auth => auth.isSoftAgentAuthenticated,
);

export const selectRedirectTo = createSelector(
  [selectDomain],
  auth => auth.redirectTo,
);

export const selectIsLoggingOut = createSelector(
  [selectDomain],
  auth => auth.isLoggingOut,
);

export const selectIsValidToken = createSelector(
  [selectDomain],
  auth => auth.isValidToken,
);

export const selectValidatingToken = createSelector(
  [selectDomain],
  auth => auth.validatingToken,
);
export const selectIsSoftCandidateAuthenticated = createSelector(
  [selectDomain],
  auth => auth.isSoftCandidateAuthenticated,
);
