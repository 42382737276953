export class Environment {
  static BASE_URL = window.location.href;

  static LOGIN_URL = process.env.REACT_APP_LOGIN_URL ?? '';

  static API_URL: string = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : '';

  static COOKIE_NAME: string = process.env.REACT_APP_COOKIE_NAME
    ? process.env.REACT_APP_COOKIE_NAME
    : '';

  static COOKIE_DOMAIN: string = process.env.REACT_APP_COOKIE_DOMAIN ?? '';

  static API_KEY: string = process.env.REACT_APP_API_KEY
    ? process.env.REACT_APP_API_KEY
    : '';
}
