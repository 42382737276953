type SeverityType = 'error' | 'info' | 'success' | 'warning';

export interface Notification {
  severityType: SeverityType;
  message: string;
}

export type AppState = { notification: Notification | null };

export enum Languages {
  EN = 'en',
}

export interface AuthUser {
  id: number | string;
  name: string;
  email: string;
  role: string;
  token: string;
}

export enum ROLES {
  ADMIN = 'Admin',
  AGENT = 'Agent',
  CANDIDATE = 'Candidate',
  SOFT_AGENT = 'SoftAgent',
  SOFT_CANDIDATE = 'SoftCandidate',
}

export interface INewPassword {
  password: string;
  confirmPassword: string;
  token: string;
}

export type NewPasswordPayload = Pick<
  INewPassword,
  'password' | 'confirmPassword' | 'token'
>;
