export interface IJob {
  id: number;
  title: string;
  shortDescription: string;
  longDescription: string;
  keyResponsibilities: string[];
  findersFee: string;
  companyName: string;
  companyLogoUrl: string;
  companyLogoEncoded?: string;
  companyUrl: string;
  companyDescription: string;
  location: string;
  confidential: boolean;
  featured: boolean;
  personalityTraits: string[];
  skills: string[];
  adminContactId: number;
  status: string;
  applicationLanguage: string;
  wentLiveAt: Date;
  wentOfflineAt: Date;
  reruns: Date;
  insertedAt: Date;
  updatedAt: Date;
  emailDescription: string;
  shouldRerun: boolean;
  previewKey: string;
  customListTitle: string;
  customListGhostTitle: string;
  internal: boolean;
  agentTagsIncluded: string[];
  agentTagsExcluded: string[];
  adminUser: IAdminUser;
  jobTags?: IJobTag[];
}

export interface IAdminUser {
  id: number;
  name: string;
  insertedAt?: Date;
  updatedAt?: Date;
  email: string;
  imageBase64: string;
  imageUrl: string;
  phone: string;
  emailFooter?: string;
}

export interface JobCard {
  id?: number;
  companyName?: string;
  companyLogoUrl?: string;
  companyUrl?: string;
  contactId?: number;
  title?: string;
  longDescription?: string;
  shortDescription?: string;
  keyResponsibilities?: string[];
  findersFee?: string;
  companyDescription?: string;
  location?: string;
  confidential?: boolean;
  featured?: boolean;
  internal?: boolean;
  personalityTraits?: string[];
  skills?: string[];
  adminUser?: IAdminUser;
  applicationLanguage?: string;
  agentTagsIncluded?: string[];
  agentTagsExcluded?: string[];
  customListGhostTitle?: string;
  customListTitle?: string;
  emailDescription?: string;
  jobTags?: IJobTag[];
}

export interface IPreviewAdmin {
  email: string;
  name: string;
  phone: string;
  imageBase64: string;
  imageUrl: string;
}

export interface IPreviewJob {
  title: string;
  longDescription: string;
  keyResponsibilities: string[];
  companyName?: string;
  companyLogoUrl?: string;
  companyUrl?: string;
  companyDescription?: string;
  location?: string;
  personalityTraits: string[];
  skills: string[];
  customListTitle: string;
  customListGhostTitle: string;
  adminUser: IPreviewAdmin;
  confidential: boolean;
  jobTags?: IJobTag[];
}

export type PreviewJobProps = {
  previewJob?: IPreviewJob;
};

export interface PreviewParams {
  previewKey: string;
}

export interface JobDetailsParams {
  jobId: string;
}

export interface CandidateJobDetailsParams {
  key: string;
}

export interface JobSelfApplication {
  externalUrl?: string;
  document?: string;
  agentId: number;
  jobId: number;
}

export type JobCardItemProps = {
  jobCard: JobCard;
};

export type JobCardsListProps = {
  handleCloseRecommendCard: Function;
  resetCandidateState: Function;
};

export interface ICandidate {
  name?: string;
  email?: string;
  agentComment?: string;
  jobId?: number;
  emailless?: boolean;
  recommendedSelf?: boolean;
  agentId?: number;
  externalUrl?: string;
  urlKey?: string;
}

export interface IAgentUser {
  adminId: number;
  company: string;
  documentUploadedAt: string;
  documentUrl: string;
  email: string;
  emailCandidateUpdates: boolean;
  emailEvents: boolean;
  emailName: string;
  emailNewJobs: boolean;
  emailNewsletter: boolean;
  externalUrl: string;
  id: number;
  insertedAt: string;
  location: string;
  name: string;
  phoneNumber: string;
  updatedAt: string;
  winner?: boolean;
  agentAdminComments?: Array<IAgentAdminComment>;
  agentAdminMeetings?: Array<IAgentAdminMeeting>;
  agentTags?: Array<IAgentTag>;
}
export interface ICandidateRecommendation {
  id?: number;
  name: string | undefined;
  email: string | undefined;
  emailless?: boolean;
  phoneNumber?: string;
  agent?: IAgentUser;
  agentComment?: string;
  agentId?: number;
  documentOriginalName?: string;
  documentS3Location?: string;
  externalUrl?: string;
  job?: JobCard | IJob | undefined;
  jobid?: boolean;
  isAgent?: boolean;
  recommendedSelf?: boolean;
  urlKey?: string;
  candidateEvents?: Array<ICandidateEvent>;
  internal: boolean;
  canOptInTalentPool?: boolean;
}

export interface CandidateJobParams {
  key: string;
}

export interface ICandidatePolicy {
  id: number;
  body: string;
  insertedAt: string;
  updatedAt: string;
  type: string;
}

export interface ICandidateApplication {
  id?: number;
  consents: boolean;
  document: File | null;
  email: string;
  externalUrl: string;
  name: string;
  phone: string;
  step: CandidateEvent;
  substep?: number;
  function?: string;
  experience?: string;
  documentPath?: string;
}

export interface ICandidateEvent {
  id?: number;
  step: string;
  substep?: string;
  candidateId: number | undefined | string;
}

export type CandidateJobApplyFormProps = {
  handleSubmitApplcation: Function;
  jobRecommendation: ICandidateRecommendation;
};

export type CandidateJobDetailsProps = {
  submitCandidateApplication: Function;
  jobRecommendation: ICandidateRecommendation;
};

export enum DeclineReason {
  HAPPY = 'happy_current',
  ROLE = 'wrong_role',
  LOCATION = 'wrong_location',
  SIMILAR_JOBS = 'similar_jobs',
}

export enum CandidateEvent {
  APPLIED = 'applied',
  SCREENED = 'screened',
  PRESENTED = 'presented',
  HIRED = 'hired',
  REJECTED = 'rejected',
  INVALID_EMAIL = 'invalid_email',
  DECLINED = 'declined',
  RECOMMENDED = 'recommended',
  ACCEPTED = 'accepted',
}

//redux
export type JobsState = {
  jobCards: Array<JobCard>;
  previewJob?: IPreviewJob;
  selfApplied?: boolean;
  recommendedCandidate?: ICandidate;
  job?: IJob;
};

export type ApplyJobPayload = Pick<
  JobSelfApplication,
  'externalUrl' | 'document' | 'agentId' | 'jobId'
>;

export type RecommendCandidatePayload = Pick<
  ICandidate,
  | 'name'
  | 'email'
  | 'agentComment'
  | 'agentId'
  | 'jobId'
  | 'emailless'
  | 'recommendedSelf'
  | 'urlKey'
  | 'externalUrl'
>;

export type JobDetailsProps = {
  handleApplyForJob: Function;
  handleRecommendCandidate: Function;
  getAgentSettings: Function;
  fetchJobById: Function;
};

export type CandidateJobState = {
  recommendation: ICandidateRecommendation;
  termsAndCondtions: ICandidatePolicy;
  submittedApplication: boolean;
  changedMind?: boolean;
  candidateEvent?: ICandidateEvent;
  candidates?: Array<ICandidateByAgent>;
  unsubscribe: string | undefined;
  candidateWithUrlKey: CandidateWithUrlKey;
};

export interface CandidateWithUrlKey {
  candidateWasAdmin: boolean;
  urlKey: string | undefined;
}

export type CandidateApplicationPayload = Pick<
  ICandidateApplication,
  | 'id'
  | 'name'
  | 'email'
  | 'phone'
  | 'document'
  | 'externalUrl'
  | 'consents'
  | 'step'
  | 'substep'
>;

export interface ICandidateByAgent {
  name: string;
  email: string;
  phoneNumber: string;
  recommendedSelf: boolean;
  urlKey: string;
  agentId: number;
  jobId: number;
  externalUrl: string;
  documentS3Location: string;
  documentOriginalName: string;
  emailless: boolean;
  internal: boolean;
  adminId: number;
  agentComment: string;
  matchRating: number;
  rank1: number;
  rank2: number;
  isAgent: boolean;
  inviteAsAgent: boolean;
  interview: boolean;
  interviewBooked: boolean;
  candidateEvents: Array<ICandidateEventByAgent>;
  gdprConsents: Array<IGDPRConsent>;
  admin: IAdminUser;
  agent: IAgentUser;
  job: IJob;
  id: number;
  insertedAt: string;
  updatedAt: string;
}

export type MyCandidatesState = {
  candidates?: Array<ICandidateByAgent>;
};

export interface ICandidateEventByAgent {
  step: string;
  substep: string;
  candidateId: number;
  id: number;
  insertedAt: string;
  updatedAt: string;
}

export interface ICandidateGroup {
  group: string;
  count: number;
  jobId: number;
  jobStatus: string;
  candidates: ICandidate[];
}

export interface IGDPRConsent {
  name: string;
  email: string;
  agentId: number;
  candidateId: number;
  policyId: number;
  withdrawnAt: string;
  id: number;
  insertedAt: string;
  updatedAt: string;
}

export interface IAgentAdminComment {
  body: string;
  agentId: number;
  authorId: number;
  id: number;
  insertedAt: string;
  updatedAt: string;
}

export interface IAgentAdminMeeting {
  status: string;
  startsAt: string;
  comment: string;
  adminId: number;
  agentId: number;
  inPerson: boolean;
  id: number;
  insertedAt: string;
  updatedAt: string;
}

export interface IAgentTag {
  label: string;
  color?: string;
  numberOfUses?: number;
  id: number;
  insertedAt?: string;
  updatedAt?: string;
}

export interface IJobTag extends IAgentTag {
  jobTaggingId?: number;
}
