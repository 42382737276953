import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from 'types/ErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  RegisterState,
  IPolicy,
  IInvitation,
  IRegisterPayload,
} from '../../models';

export const initialState: RegisterState = {
  loading: false,
  error: null,
  policy: {
    id: 0,
    body: '',
    type: '',
    insertedAt: '',
    updatedAt: '',
  },
  invitation: {
    name: '',
    email: '',
  },
};

const RegistrationSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    fetchPolicy(state, action: PayloadAction<string>) {
      state.loading = true;
      state.error = null;
    },
    setPolicy(state, { payload }: PayloadAction<IPolicy>) {
      state.loading = false;
      state.policy = payload;
    },
    fetchRegistrationInvitation(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    setRegistrationInvitation(state, { payload }: PayloadAction<IInvitation>) {
      state.loading = false;
      state.invitation = payload;
    },
    registerAgent(state, action: PayloadAction<IRegisterPayload>) {
      state.loading = true;
    },
    setRegistered(state, { payload }: PayloadAction<IRegisterPayload>) {
      state.registeredAgent = payload;
    },
    resetRegisteredAgent(state) {
      state.registeredAgent = undefined;
      state.loading = false;
    },
    error(state, action: PayloadAction<ErrorType>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = RegistrationSlice;
