import { call, put, takeLatest } from 'redux-saga/effects';
import { ApiClient } from 'utils/api';
import { actions } from './slice';
import { Action, AuthUser, ROLES } from 'models';

export function* authenticateAgentHandler({ payload }: Action) {
  const requestURL = `/Account/authenticate`;

  try {
    const authUser: AuthUser = yield call(ApiClient.post, requestURL, payload);

    switch (authUser.role) {
      case ROLES.AGENT: {
        localStorage.setItem('AUTH_AGENT', JSON.stringify(authUser));
        localStorage.setItem('AUTH_AGENT_TOKEN', authUser.token);
        yield put(actions.setAuthenticatedUser(authUser));
        return;
      }
      case ROLES.CANDIDATE: {
        localStorage.setItem('AUTH_CANDIDATE', JSON.stringify(authUser));
        localStorage.setItem('AUTH_CANDIDATE_TOKEN', authUser.token);
        yield put(actions.setAuthenticatedCandidate(authUser));
        return;
      }
      default:
        return;
    }
  } catch (ex) {
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}

export function* fetchAuthenticatedUserHandler({ payload }: Action) {
  const requestURL = `/Account/authenticate`;
  try {
    const authUser: AuthUser = yield call(ApiClient.get, requestURL);
    switch (authUser.role) {
      case ROLES.SOFT_AGENT: {
        localStorage.setItem('AUTH_SOFT_AGENT', JSON.stringify(authUser));
        localStorage.setItem('AUTH_SOFT_AGENT_TOKEN', payload);
        yield put(actions.setSoftAuthenticatedAgent(authUser));
        return;
      }
      case ROLES.SOFT_CANDIDATE: {
        localStorage.setItem('AUTH_SOFT_CANDIDATE', JSON.stringify(authUser));
        localStorage.setItem('AUTH_SOFT_CANDIDATE_TOKEN', payload);
        yield put(actions.setSoftAuthenticatedCandidate(authUser));
        return;
      }
      default:
        return;
    }
  } catch (ex) {
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}

export function* resetPasswordHandler({ payload }: Action) {
  try {
    yield call(
      ApiClient.post,
      `/Account/requestResetPassword?email=${payload.email}`,
    );

    yield put(actions.setResetPassword(payload.email));
  } catch (ex) {
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}

export function* createNewPasswordHandler({ payload }: Action) {
  try {
    const token = payload.token;
    yield call(
      ApiClient.post,
      `/Account/resetPassword?password=${payload.password}&confirmPassword=${payload.confirmPassword}&token=${token}`,
    );

    yield put(actions.createNewPasswordSuccess());
  } catch (ex) {
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}

export function* logoutUserHandler({ payload }: Action) {
  try {
    if (!payload) {
      yield call(ApiClient.post, '/Account/logout');
    }
    localStorage.removeItem('AUTH_AGENT');
    localStorage.removeItem('AUTH_AGENT_TOKEN');
    localStorage.removeItem('AUTH_SOFT_AGENT');
    localStorage.removeItem('AUTH_SOFT_AGENT_TOKEN');
    yield put(actions.resetAuthState());
  } catch (ex) {
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}
export function* validateTokenHandler({ payload }: Action) {
  try {
    yield call(
      ApiClient.post,
      `/Account/resetPassword/validate?token=${payload}`,
    );

    yield put(actions.setValidatedToken(true));
  } catch (ex) {
    yield put(actions.setValidatedToken(false));
    yield put(actions.error({ message: 'Not authorized', status: 401 }));
  }
}

export function* authenticationSaga() {
  yield takeLatest(actions.authenticateUser.type, authenticateAgentHandler);
  yield takeLatest(
    actions.fetchAuthenticatedUser.type,
    fetchAuthenticatedUserHandler,
  );
  yield takeLatest(actions.resetPassword.type, resetPasswordHandler);
  yield takeLatest(actions.createNewPassword.type, createNewPasswordHandler);
  yield takeLatest(actions.logoutUser.type, logoutUserHandler);
  yield takeLatest(actions.validateToken.type, validateTokenHandler);
}
